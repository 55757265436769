import get from 'lodash/get';
import { retrieveListOrObject, retrieveObjectMapper } from '../..';

/**
 *
 * @param {*} resp
 * @returns
 */
export const modifyCentreList = (resp = {}) => {
  const lists = retrieveListOrObject(resp);
  return {
    data: {
      listPimCentreDetails: {
        data: lists?.map(list =>
          retrieveObjectMapper(list, [
            'ID:id',
            'Name:name',
            'Code:code',
            'IsEnabled:is_enabled',
            'Email:email',
            'UpdatedBy:updated_by',
            'UpdatedAt:updated_at',
          ])
        ),
      },
    },
  };
};

/**
 *
 * @param {*} resp
 * @returns
 */
export const modifyCentreDetails = (resp = {}) => {
  const centre = resp?.data;
  return {
    data: {
      getPimCentreDetails: {
        ...retrieveObjectMapper(centre, [
          'ID:id',
          'Name:name',
          'Code:code',
          'IsEnabled:is_enabled',
          'Email:email',
          'UpdatedBy:updated_by',
          'UpdatedAt:updated_at',
        ]),
        Categories: centre?.categories?.map(category => ({
          ...retrieveObjectMapper(category, [
            'ID:id',
            'Name:name',
            'Ordering:ordering',
            'IsShown:is_shown',
            'TouchLevel:touch_level',
            'MappedTo:mapped_to',
          ]),
          Mapping: category?.mapping?.map(mapping => ({
            ...retrieveObjectMapper(mapping, [
              'ID:id',
              'IsChecked:is_checked',
              'TouchLevel:touch_level',
            ]),
            Role: retrieveObjectMapper(mapping?.role, [
              'ID:id',
              'Name:name',
              'Label:label',
            ]),
          })),
          Categories: category?.categories?.map(subCategory =>
            retrieveObjectMapper(subCategory, [
              'ID:id',
              'Name:name',
              'Ordering:ordering',
              'IsShown:is_shown',
            ])
          ),
        })),
      },
    },
  };
};

/**
 *
 * @param {*} reqData object {update: {}}
 * @returns object
 */
export const prepareUpdatePIMReqBody = (reqData = {}) => {
  const reqBody = get(reqData, 'update', {}) || {};
  return {
    id: reqBody?.ID,
    is_enabled: reqBody?.IsEnabled,
    categories: get(reqBody, 'Categories', [])?.map(category => ({
      id: category?.ID,
      is_shown: category?.IsShown,
      ordering: category?.Ordering,
      touch_level: category?.TouchLevel,
      mapped_to: category?.MappedTo,
      mapping: get(category, 'Mapping', [])?.map(mapping => ({
        id: mapping?.ID,
        is_checked: mapping?.IsChecked,
      })),
      categories: get(category, 'Categories', [])?.map(subCategory => ({
        id: subCategory?.ID,
        is_shown: subCategory?.IsShown,
        ordering: subCategory?.Ordering,
      })),
    })),
  };
};

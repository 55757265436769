// eslint-disable-next-line no-unused-vars
/* eslint-disable no-lonely-if */
import { get, find } from 'lodash';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { decodePssSurveyLink } from '../../../../utils';
import DatadogHandler from '../../../../utils/datadog';
import {
  CHARACTER_LIMIT_5000,
  DEFAULT_ERROR,
  ERROR,
  FEATURE_FLAGS,
} from '../../../../utils/constants';
import useFeatureFlag from '../../../../hooks/useFeatureFlag';

let tabSurveyAnswers = [];
let CURRENT_QUESTION = 1;
const Handler = ({
  fetchPssQuestions,
  savePssSurveyResponse,
  setUpdateResponseLoading,
  showSnackBarMessage,
  msGetSurveyDetailsFromSurveyUuid,
  msGetSurveyProgress,
}) => {
  const history = useHistory();
  const [questions, setQuestions] = useState({});
  const [currentQuestionNo, setCurrentQuestionNo] = useState(1);
  const [centreID, setCentreID] = useState(null);
  const [childID, setChildID] = useState(null);
  const [parentID, setParentID] = useState(null);
  const [schoolID, setSchoolID] = useState(null);
  const [hashKey, setHashKey] = useState(null);
  const [serviceLevel, setServiceLevel] = useState(null);
  const [surveyID, setSurveyID] = useState(null);
  const [surveyAnswers, setSurveyAnswers] = useState([]);
  const [showSurveySuccessScreen, setShowSurveySuccessScreen] = useState(false);
  const [childName, setChildName] = useState('');
  const [isDisabledButtonClick, setDisabledButtonClick] = useState(false);
  const [source, setSource] = useState(null);
  const [submitErr, setSubmitErr] = useState(false);
  const [uuid, setUuid] = useState(null);
  const [surveyPercentage, setSurveyPercentage] = useState(null);

  const dispatch = useDispatch();

  const treatment = useFeatureFlag(FEATURE_FLAGS.MS_PHASE_2C_PIM_PSS_DISABLED);
  const isMs = treatment !== 'on';

  let tabChildID = null;
  let tabSurveyID = null;
  let tabParentID = null;
  let tabHashKey = null;
  let tabSource = null;

  let ALL_QUESTIONS = {};
  let firstQuestionNumber = null;

  const params = useParams();

  const questionData = get(questions, 'data.getSurveyQuestions', []);
  let currentQuestionData = questionData.filter(
    da => get(da, 'questionNo') === currentQuestionNo
  );
  currentQuestionData = currentQuestionData[0];

  firstQuestionNumber = get(
    questions,
    'data.getSurveyQuestions[0].questionNo',
    null
  );

  let isSection = false;
  let filterCurrentSectionData = [];

  if (get(currentQuestionData, 'sectionLabel')) {
    isSection = true;
    filterCurrentSectionData = get(
      questions,
      'data.getSurveyQuestions',
      []
    ).filter(sl => sl.questionNo === currentQuestionNo);
  }

  let disableNextBtn = true;
  if (!isSection) {
    const currentQuestionId = get(currentQuestionData, 'ID');
    const findPosition = surveyAnswers.findIndex(
      sl => sl?.ID === currentQuestionId
    );
    if (findPosition >= 0) {
      disableNextBtn = false;
    }
  } else if (isSection) {
    let counter = 0;
    surveyAnswers.forEach(item1 => {
      filterCurrentSectionData.forEach(item2 => {
        if (get(item1, 'ID') === get(item2, 'ID')) {
          counter += 1;
        }
      });
    });
    if (get(filterCurrentSectionData, 'length', 0) === counter) {
      disableNextBtn = false;
    }
  }

  const isTextInputsEmpty = () => {
    const currentSectionQuestions = questionData.filter(
      item => get(item, 'questionNo') === currentQuestionNo
    );
    let shouldDisable = false;

    const currentSurveyAnswers = surveyAnswers.filter(item => {
      if (find(currentSectionQuestions, { ID: get(item, 'ID') })) {
        return item;
      }
    });

    currentSurveyAnswers.forEach(csa => {
      if (
        !get(csa, 'responseID') &&
        get(csa, 'response').replace(/\s/g, '') === ''
      ) {
        shouldDisable = true;
      }
    });

    return shouldDisable;
  };

  const questionLength = questionData[questionData.length - 1];

  const getChildAndSurveryID = () => {
    const decodedValue = decodePssSurveyLink(params?.id, isMs);
    const splitDecodedValue = decodedValue.split('&');
    const splitDecodedValueUuid = decodedValue.split('?');
    if (isMs && !uuid) {
      setUuid(splitDecodedValueUuid[0]);
      const [param, val] = splitDecodedValueUuid[1].split('=');
      if (param === 'source') {
        setSource(val);
        tabSource = val;
      }
    }
    let childId = null;
    let surveyId = null;
    splitDecodedValue.forEach(item => {
      if (item.startsWith('hashKey=')) {
        tabHashKey = item.slice(8); // Remove 'hashKey='
        setHashKey(tabHashKey);
      } else {
        const [key, value] = item.split('=');
        switch (key) {
          case 'centreID':
            setCentreID(parseInt(value, 10));
            break;
          case 'childID':
            setChildID(parseInt(value, 10));
            childId = parseInt(value, 10);
            tabChildID = parseInt(value, 10);
            break;
          case 'parentID':
            setParentID(parseInt(value, 10));
            tabParentID = parseInt(value, 10);
            break;
          case 'schoolID':
            setSchoolID(parseInt(value, 10));
            break;
          case 'serviceLevel':
            setServiceLevel(value.replace(/\+/g, ' '));
            break;
          case 'surveyID':
            setSurveyID(parseInt(value, 10));
            surveyId = parseInt(value, 10);
            tabSurveyID = parseInt(value, 10);
            break;
          case 'source':
            setSource(value);
            tabSource = value;
            break;
          default:
            break;
        }
      }
    });

    return { surveyId, childId };
  };

  const retrieveSurveyData = async () => {
    const { surveyId, childId } = getChildAndSurveryID();
    const reqData = {
      surveyID: surveyId || surveyID,
      childID: childId || childID,
      uuid,
    };
    if ((surveyId && childId) || (surveyID && childID)) {
      const resp = await fetchPssQuestions(reqData);
      setQuestions(resp);
      ALL_QUESTIONS = JSON.parse(JSON.stringify(resp));
      const initialAnswersArray = [];
      let checkCurrentQuestionStatus = true;
      get(resp, 'data.getSurveyQuestions', []).map((item, idx) => {
        if (!get(item, 'answer') && checkCurrentQuestionStatus) {
          setCurrentQuestionNo(get(item, 'questionNo'));
          CURRENT_QUESTION = get(item, 'questionNo');
          checkCurrentQuestionStatus = false;
        }
        if (get(item, 'answer')) {
          initialAnswersArray.push({
            ID: get(item, 'ID'),
            response: get(item, 'answer'),
            responseID: get(item, 'answerID'),
            questionSectionId: get(item, 'questionSectionId'),
          });
          if (
            checkCurrentQuestionStatus &&
            get(resp, 'data.getSurveyQuestions.length', 0) === idx + 1
          ) {
            setCurrentQuestionNo(get(item, 'questionNo'));
            CURRENT_QUESTION = get(item, 'questionNo');
          }
        }
      });
      setSurveyAnswers(initialAnswersArray);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await retrieveSurveyData();
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }, 100);
    };

    fetchData();
  }, [childID, surveyID]);

  const saveQuestionAnswers = (data, index, type = '', text = '') => {
    if (type === 'text' && text?.length > CHARACTER_LIMIT_5000) {
      return;
    }
    const findPosition = surveyAnswers.findIndex(sl => sl?.ID === data?.ID);
    if (findPosition >= 0) {
      if (type !== 'text') {
        surveyAnswers[findPosition].responseID = get(
          data,
          `responseID.${index}`
        );
        surveyAnswers[findPosition].response = get(data, `response.${index}`);
      } else {
        surveyAnswers[findPosition].responseID = null;
        surveyAnswers[findPosition].response = text;
      }
    } else {
      surveyAnswers.push({
        ID: get(data, 'ID'),
        responseID: type === 'text' ? null : get(data, `responseID[${index}]`),
        response: type === 'text' ? text : get(data, `response[${index}]`),
        questionSectionId: get(data, 'questionSectionId'),
      });
    }
    setSurveyAnswers(surveyAnswers.concat([]));
    tabSurveyAnswers = surveyAnswers.concat([]);
  };

  const updateSurveyQuestions = async (
    type = 'normal',
    isCompleted = false
  ) => {
    const allQuestions = get(
      type === 'normal' ? questions : ALL_QUESTIONS,
      'data.getSurveyQuestions'
    );
    const surveyAnswersArray = [];
    let msSectionId = null;
    const answerArray =
      type === 'tab-switch' ? tabSurveyAnswers : surveyAnswers;
    // eslint-disable-next-line no-unused-expressions
    answerArray.map(items => {
      const itemSectionData = find(allQuestions, { ID: get(items, 'ID') });
      const itemSectionID = get(itemSectionData, 'questionNo');

      if (
        itemSectionID ===
        (type === 'normal' ? currentQuestionNo : CURRENT_QUESTION)
      ) {
        if (!items?.responseID) {
          if (isMs) {
            surveyAnswersArray.push({
              question_id: items?.ID,
              response: items?.response,
            });
          } else {
            surveyAnswersArray.push({
              questionID: items?.ID,
              response: items?.response,
            });
          }
        } else {
          if (isMs) {
            surveyAnswersArray.push({
              question_id: items?.ID,
              response_id: items?.responseID,
              response: items?.response,
            });
            msSectionId = items?.questionSectionId;
          } else {
            surveyAnswersArray.push({
              questionID: items?.ID,
              responseID: items?.responseID,
              response: items?.response,
            });
          }
        }
      }
    });
    let reqData = {};
    if (type === 'tab-switch') {
      if (isMs) {
        reqData = {
          child_id: tabChildID,
          is_completed: isCompleted,
          parent_id: tabParentID,
          question_section_id: msSectionId,
          source: tabSource,
          survey_id: tabSurveyID,
          survey_response: surveyAnswersArray,
        };
      } else {
        reqData = {
          parentID: tabParentID,
          surveyID: tabSurveyID,
          childID: tabChildID,
          hashKey: tabHashKey,
          surveyResponse: surveyAnswersArray,
          isCompleted,
          source: tabSource,
        };
      }
    } else {
      if (isMs) {
        reqData = {
          child_id: childID,
          is_completed: isCompleted,
          parent_id: parentID,
          question_section_id: msSectionId,
          source,
          survey_id: surveyID,
          survey_response: surveyAnswersArray,
        };
      } else {
        reqData = {
          parentID,
          surveyID,
          childID,
          hashKey,
          surveyResponse: surveyAnswersArray,
          isCompleted,
          source,
        };
      }
    }
    const res = await savePssSurveyResponse(reqData, uuid);
    const logMessage = isCompleted ? 'PSS Submit' : 'PSS Save';
    let logLevel = 'info'; // Default log level

    // Check if the response is null or empty
    if (!res || !get(res, 'data.updateSurveyResponse')) {
      logLevel = 'error';
      dispatch(showSnackBarMessage(DEFAULT_ERROR, ERROR));
    } else {
      setChildName(get(res, 'data.updateSurveyResponse', ''));
    }

    const errorResp = res && (res.errors || res.error);
    if (errorResp) {
      logLevel = 'error';
      reqData.errors = errorResp;
    }

    DatadogHandler.sendLog(logMessage, reqData, logLevel);
    setUpdateResponseLoading(false);
    return res;
  };

  // // Removing the tab-switch auto save logic
  // useEffect(() => {
  //   const handleVisibilityChange = async () => {
  //     if (document.hidden) {
  //       await updateSurveyQuestions('tab-switch');
  //     }
  //   };

  //   document.addEventListener('visibilitychange', () => {
  //     handleVisibilityChange().catch(error => {
  //       console.error('Error in handleVisibilityChange:', error);
  //     });
  //   });

  //   return () => {
  //     document.removeEventListener('visibilitychange', () => {
  //       handleVisibilityChange().catch(error => {
  //         console.error('Error in handleVisibilityChange:', error);
  //       });
  //     });
  //   };
  // }, []);

  const updateQuestionNumber = async (type = 'next') => {
    let nextQuestion = currentQuestionNo + 1;
    let previousQuestion = currentQuestionNo - 1;
    let isNextSectionFound = false;

    /* eslint-disable no-loop-func */
    while (!isNextSectionFound) {
      get(questions, 'data.getSurveyQuestions', []).forEach(item => {
        if (type === 'next' && get(item, 'questionNo') === nextQuestion) {
          isNextSectionFound = true;
        } else if (
          type !== 'next' &&
          get(item, 'questionNo') === previousQuestion
        ) {
          isNextSectionFound = true;
        }
      });

      if (!isNextSectionFound) {
        nextQuestion++;
        previousQuestion--;
      }
    }
    /* eslint-enable no-loop-func */

    if (type === 'next') {
      const response = await updateSurveyQuestions();
      const notError = response && !(response.errors || response.error);
      if (notError) {
        setCurrentQuestionNo(type === 'next' ? nextQuestion : previousQuestion);
        CURRENT_QUESTION = type === 'next' ? nextQuestion : previousQuestion;
        setUpdateResponseLoading(false);
        setTimeout(() => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        }, 100);
      } else {
        dispatch(showSnackBarMessage(DEFAULT_ERROR, ERROR));
        setUpdateResponseLoading(false);
      }
    } else {
      setCurrentQuestionNo(type === 'next' ? nextQuestion : previousQuestion);
      CURRENT_QUESTION = type === 'next' ? nextQuestion : previousQuestion;
    }
  };

  const hasResponseCount6 = filterCurrentSectionData.some(
    item => item.response.length === 6
  );

  const handleReload = () => {
    history.go(0);
  };

  const getUuidData = async () => {
    if (uuid) {
      const res = await msGetSurveyDetailsFromSurveyUuid(uuid);
      if (res) {
        setSchoolID(get(res, 'schoo_idl'));
        setCentreID(get(res, 'centre_id'));
        setChildID(get(res, 'child_id'));
        setServiceLevel(get(res, 'level_label', ''));
        setParentID(get(res, 'parent_id'));
        setSurveyID(get(res, 'survey_id'));
      }
    }
  };

  useEffect(() => {
    getUuidData();
  }, [uuid]);

  const getSurveyPercentageProgress = async () => {
    if (!childID || !surveyID) {
      return;
    }
    const reqData = {
      pssToken: uuid,
      childId: childID,
      surveyId: surveyID,
    };
    const res = await msGetSurveyProgress(reqData);
    setSurveyPercentage(get(res, 'percentage'));
  };

  useEffect(() => {
    getSurveyPercentageProgress();
  }, [currentQuestionNo, childID, surveyID]);

  return {
    questions,
    updateQuestionNumber,
    currentQuestionNo,
    centreID,
    childID,
    parentID,
    serviceLevel,
    schoolID,
    surveyID,
    saveQuestionAnswers,
    surveyAnswers,
    updateSurveyQuestions,
    showSurveySuccessScreen,
    setShowSurveySuccessScreen,
    childName,
    isTextInputsEmpty,
    disableNextBtn,
    questionLength,
    currentQuestionData,
    isSection,
    filterCurrentSectionData,
    questionData,
    isDisabledButtonClick,
    setDisabledButtonClick,
    retrieveSurveyData,
    getChildAndSurveryID,
    hasResponseCount6,
    setSubmitErr,
    submitErr,
    handleReload,
    surveyPercentage,
    firstQuestionNumber,
    isMs,
  };
};

export default Handler;

/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
import React from 'react';
import compose from 'recompose/compose';
import { get } from 'lodash';
import { useParams } from 'react-router';
import { connect } from 'react-redux';
import { Grid, Button } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { withTranslation } from 'react-i18next';
import Text from 'components/common/Text';
import {
  fetchPssQuestions,
  getPssSurveyConfig,
  msGetSurveyDetailsFromSurveyUuid,
} from 'redux/actions';
import {
  getPssFormWidthForDifferentScreens,
  ImageLazyLoader,
  getSchoolData,
} from 'utils';
import { schoolIDMap } from 'utils/constants';
import Questions from './Questions';
import SurveySuccessScreen from './Questions/SurveySuccessScreen';
import useStyles from './style';
import Handler from './ParentSatisfactionSurvey.handler';
import { decodePssSurveyLink } from '../../../utils';

const ParentSatisfactionSurvey = props => {
  const {
    showQuestions,
    setShowQuestions,
    showWelcomeScreen,
    surveyCompleted,
    surveyExpired,
    serviceLevel,
    isMs,
    setUuid,
    uuid,
    schoolID,
  } = Handler(props);

  const classes = useStyles();
  const params = useParams();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  let schoolId = null;
  const decodedValue = decodePssSurveyLink(params?.id, isMs);
  const splitDecodedValue = decodedValue.split('&');
  if (isMs && !uuid) {
    const msDecodedValue = decodedValue && decodedValue.split('?');
    setUuid(msDecodedValue[0]);
  }
  splitDecodedValue.forEach(item => {
    const splitValue = item.split('=');
    if (splitValue[0] === 'schoolID') {
      schoolId = parseInt(splitValue[1], 10);
    }
  });

  const { t } = props;

  schoolId = schoolId || schoolID;

  const { imageStyle, schoolLogo, envUrl } = getSchoolData(schoolId, 'pss');

  const getSchoolName = schoolId => {
    return get(schoolIDMap, `[${schoolId}].name`);
  };

  const getSchoolCHName = schoolId => {
    return get(schoolIDMap, `[${schoolId}].chineseName`);
  };

  if (surveyCompleted || surveyExpired) {
    return (
      <Grid container className={classes.surveyContainer}>
        <Grid item xs={12} className={classes.innerSurveyContainer}>
          <Grid container justify="center">
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="w3-center s7t-padding-bottom-30 s7t-padding-top-30"
            >
              <Grid container direction="row" className="sn2-d-flex">
                <Grid md={12} xs={12}>
                  <ImageLazyLoader
                    alt="school banner for survey"
                    className="s7t-full-height s7t-login-grid-image"
                    src={schoolLogo}
                    style={imageStyle}
                  />
                </Grid>
                {serviceLevel && (
                  <Grid xs={12} className="s7t-pss-header">
                    <Text color="white">
                      {t('common.submittingSurveyFor', { serviceLevel })}
                    </Text>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              className=" s7t-pss-welcome-screen-container "
            >
              <SurveySuccessScreen
                t={t}
                surveyStatusMessage={
                  surveyCompleted
                    ? t('common.surveySubmitted')
                    : surveyExpired
                    ? t('common.surveyExpired')
                    : null
                }
                successIcon={
                  surveyCompleted ? true : surveyExpired ? false : true
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  if (showQuestions) {
    return <Questions />;
  }

  if (showWelcomeScreen) {
    return (
      <Grid
        container
        className={classes.welcomeScreenContainer}
        style={{
          backgroundImage: `url(${envUrl}/${
            schoolId === 1 ? 'MFS' : 'LSH'
          }/pss-bg-${schoolId === 1 ? 'mfs' : 'lsh'}.png)`,
        }}
      >
        <Grid item xs={12} className={classes.welcomeScreenInnerContainer}>
          <Grid container justify="center">
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="w3-center s7t-padding-bottom-30 s7t-padding-top-30"
            >
              <ImageLazyLoader
                alt="school banner for survey"
                className="s7t-full-height s7t-login-grid-image"
                src={schoolLogo}
                style={imageStyle}
              />
            </Grid>
            {serviceLevel && (
              <Grid
                xs={12}
                className={
                  isXs
                    ? 's7t-pss-header-mobile w3-center s7t-padding-bottom-30'
                    : 's7t-pss-header w3-center s7t-padding-bottom-30'
                }
              >
                <Text color="white">
                  {t('common.submittingSurveyFor', { serviceLevel })}
                </Text>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              md={getPssFormWidthForDifferentScreens()}
              className={
                isXs
                  ? `s7t-pss-welcome-screen-container-mobile`
                  : `s7t-pss-welcome-screen-container`
              }
            >
              <Grid
                className={
                  isXs
                    ? 's7t-padding-bottom-20'
                    : 'w3-center s7t-padding-bottom-30'
                }
              >
                <Grid item>
                  <Text
                    className={
                      isXs
                        ? 's7t-pss-welcome-page-title-mobile'
                        : 's7t-pss-welcome-page-title'
                    }
                  >
                    {t('common.parentSatisfactionSurvey1')}
                  </Text>
                </Grid>
                <Grid item>
                  <Text
                    className={
                      isXs
                        ? 's7t-pss-welcome-page-title-mobile'
                        : 's7t-pss-welcome-page-title'
                    }
                  >
                    {t('common.parentSatisfactionSurvey2')}
                  </Text>
                </Grid>
              </Grid>
              <Grid className={!isXs && 's7t-margin-top-30'}>
                <Grid container spacing={2} xs={12}>
                  <Grid item md={6}>
                    <Grid>
                      <Text className="s7t-pss-invite-subtext">
                        {t('common.pssInviteText1')}
                      </Text>
                    </Grid>
                    <Grid
                      className={
                        isXs ? 's7t-margin-top-10' : 's7t-margin-top-30'
                      }
                    >
                      <Text className="s7t-pss-invite-subtext">
                        {t('common.pssInviteText2', {
                          schoolName: getSchoolName(schoolId),
                        })}
                      </Text>
                    </Grid>
                    <Grid
                      className={
                        isXs ? 's7t-margin-top-10' : 's7t-margin-top-30'
                      }
                    >
                      <Text className="s7t-pss-invite-subtext">
                        {t('common.pssInviteText3')}
                      </Text>
                    </Grid>
                    <Grid
                      className={
                        isXs
                          ? 's7t-margin-top-10'
                          : 's7t-margin-top-30 s7t-margin-bottom'
                      }
                    >
                      <Text className="s7t-pss-invite-subtext">
                        {t('common.pssInviteText4', {
                          schoolName: getSchoolName(schoolId),
                        })}
                      </Text>
                    </Grid>
                  </Grid>
                  <Grid item md={6}>
                    <Grid>
                      <Text className="s7t-pss-invite-subtext">
                        {t('common.pssInviteTextZH1')}
                      </Text>
                    </Grid>
                    <Grid
                      className={
                        isXs ? 's7t-margin-top-10' : 's7t-margin-top-30'
                      }
                    >
                      <Text className="s7t-pss-invite-subtext">
                        {t('common.pssInviteTextZH2', {
                          schoolName: getSchoolName(schoolId),
                          schoolNameCH: getSchoolCHName(schoolId),
                        })}
                      </Text>
                    </Grid>
                    <Grid
                      className={
                        isXs ? 's7t-margin-top-10' : 's7t-margin-top-30'
                      }
                    >
                      <Text className="s7t-pss-invite-subtext">
                        {t('common.pssInviteTextZH3')}
                      </Text>
                    </Grid>
                    <Grid
                      className={
                        isXs
                          ? 's7t-margin-top-10'
                          : 's7t-margin-top-30 s7t-margin-bottom'
                      }
                    >
                      <Text className="s7t-pss-invite-subtext">
                        {t('common.pssInviteTextZH4', {
                          schoolNameCH: getSchoolCHName(schoolId),
                        })}
                      </Text>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid className="w3-center s7t-margin-top-30">
                <Button
                  className={classes.nextButton}
                  onClick={() => setShowQuestions(true)}
                >
                  <Text className="s7t-pss-primary-btn s7t-pss-padding">
                    {t('common.next')}
                  </Text>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
  return <Grid />;
};

const mapDispatchToProps = {
  fetchPssQuestions,
  getPssSurveyConfig,
  msGetSurveyDetailsFromSurveyUuid,
};

export default compose(withTranslation())(
  connect(null, mapDispatchToProps)(ParentSatisfactionSurvey)
);

import { useState } from 'react';
import { get } from 'lodash';
import {
  CENTRE_EMAIL,
  TECHNICAL_ASSISTANCE,
} from '../../../../utils/constants';

const UpdateCentrePIMHandler = props => {
  const {
    centreDetails,
    setShowLoader,
    setIsEdit,
    showSnackBarMessage,
    updatePIMCentreDetails,
    updatePIMCentreDetailsBulk,
  } = props;

  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [showModal4, setShowModal4] = useState(false);
  const [centreData, setCentreData] = useState(centreDetails);
  const [tempTouchLevel, setTempTouchLevel] = useState(null);
  const [tempIconShowValue, setTempIconShowValue] = useState(null);
  const [tempRole, setTempRole] = useState(null);

  const handleSelectChange = () => {
    const targetName = 'TouchLevel';
    const newValue = get(tempTouchLevel, 'value', '');
    const id = get(tempTouchLevel, 'catId', '');
    const updatedData = { ...centreData };
    const foundIndex = get(updatedData, 'Categories', []).findIndex(
      category => category.ID === id
    );

    if (foundIndex !== -1) {
      updatedData.Categories[foundIndex][targetName] = newValue;
      setCentreData(updatedData);
      setTempTouchLevel(null);
    }
  };

  const handleChange = event => {
    const targetName = event.target.name;
    const newValue = event.target.value;

    setCentreData(prevCentreData => ({
      ...prevCentreData,
      [targetName]: targetName === 'IsEnabled' ? newValue === '0' : newValue,
    }));
  };

  const handleMappingChange = (value, id) => {
    const newValue = value;
    const targetName = 'Mapping';
    const newMappingId = new Set(newValue.map(item => item.mappingId));

    const updatedData = { ...centreData };
    const foundIndex = get(updatedData, 'Categories', []).findIndex(
      category => category.ID === id
    );

    if (foundIndex !== -1) {
      const updatedMapping = get(
        centreData,
        `Categories[${foundIndex}].${targetName}`,
        []
      ).filter(
        m => m.TouchLevel === centreData.Categories[foundIndex].TouchLevel
      );

      const originalData = get(
        centreData,
        `Categories[${foundIndex}].${targetName}, []`
      );
      const updatedMappingWithIsChecked = updatedMapping.map(item => {
        if (newMappingId.has(item.ID)) {
          item.IsChecked = true;
        } else {
          item.IsChecked = false;
        }
        return item;
      });

      if (Array.isArray(originalData)) {
        const updatedDataCopy = { ...updatedData };
        updatedDataCopy.Categories[foundIndex][targetName] =
          updatedMappingWithIsChecked;

        updatedDataCopy.Categories[foundIndex][targetName] = [
          ...updatedDataCopy.Categories[foundIndex][targetName],
          ...originalData.filter(
            item => !updatedMappingWithIsChecked.some(i => i.ID === item.ID)
          ),
        ];
        setCentreData(updatedDataCopy);
      }
    }
  };

  const handleRoleChange = (value = '', id = null) => {
    const targetName = 'MappedTo';
    const newValue = value || tempRole.value;
    const catId = id || tempRole.id;

    const updatedData = { ...centreData };
    const foundIndex = updatedData.Categories.findIndex(
      category => category.ID === catId
    );

    if (foundIndex !== -1) {
      if (newValue === CENTRE_EMAIL) {
        updatedData.Categories[foundIndex].TouchLevel = 'low';
      }
      updatedData.Categories[foundIndex][targetName] = newValue;
      setCentreData(updatedData);
      setTempRole(false);
    }
  };

  const handleIconClick = (value = '', id = '') => {
    const nValue = value || tempIconShowValue.value;
    const nId = id || tempIconShowValue.catId;

    const targetName = 'IsShown';
    const updatedData = { ...centreData };
    const foundIndex = updatedData.Categories.findIndex(
      category => category.ID === nId
    );

    if (foundIndex !== -1) {
      updatedData.Categories[foundIndex][targetName] = nValue;
      setCentreData(updatedData);
      setTempIconShowValue(null);
    }
  };

  const handleHideSub = (value, catId, id) => {
    const targetName = 'IsShown';
    const updatedData = { ...centreData };

    const catIndex = get(updatedData, 'Categories', []).findIndex(
      category => category.ID === catId
    );

    if (catIndex !== -1) {
      const subCatIndex = get(
        updatedData,
        `Categories[${catIndex}].Categories`,
        []
      ).findIndex(sub => sub.ID === id);

      if (subCatIndex !== -1) {
        updatedData.Categories[catIndex].Categories[subCatIndex][targetName] =
          value;
        setCentreData(updatedData);
      }
    }
  };

  const getPostData = input => {
    return {
      ID: input.ID,
      IsEnabled: input.IsEnabled,
      Categories: get(input, 'Categories', []).map(category => ({
        ID: category.ID,
        TouchLevel: category.TouchLevel,
        Ordering: category.Ordering,
        IsShown: category.IsShown,
        MappedTo: category.MappedTo,
        Mapping: get(category, 'Mapping', [])
          .filter(m => m.TouchLevel === category.TouchLevel)
          .map(mapping => ({
            ID: mapping.ID,
            IsChecked: mapping.IsChecked,
          })),
        Categories: (get(category, 'Categories', []) || []).map(
          subCategory => ({
            ID: subCategory.ID,
            Ordering: subCategory.Ordering,
            IsShown: subCategory.IsShown,
          })
        ),
      })),
    };
  };

  const handleSubmit = (type = '') => {
    setShowLoader(true);
    const postData = centreData;
    let isValid = true;
    let errorMsg = '';
    const categories = get(postData, 'Categories', []).filter(
      c => c.Name !== TECHNICAL_ASSISTANCE
    );

    for (let i = 0; i < categories.length; i++) {
      const cat = categories[i];
      if (get(cat, 'MappedTo') === 'roles') {
        const currRoles = cat.Mapping.filter(
          item => item.TouchLevel === cat.TouchLevel
        );
        if (currRoles.every(role => !role.IsChecked)) {
          errorMsg =
            'Mapping cannot be empty. Please select at least one role.';
          isValid = false;
          break;
        }
      }
    }

    if (isValid) {
      const wData = getPostData(postData);
      const updateFunction =
        type === 'all' ? updatePIMCentreDetailsBulk : updatePIMCentreDetails;
      updateFunction({
        update: wData,
      })
        .then(response => {
          if (response.success) {
            setShowLoader(false);
            setIsEdit(false);
            showSnackBarMessage('PIM Centre Config Updated Successfully');
          } else {
            showSnackBarMessage(get(response, 'error[0].message'), 'error');
            setIsEdit(false);
          }
          setShowModal(false);
          setIsEdit(false);
        })
        .catch(error => {
          setShowModal(false);
          setIsEdit(false);
          showSnackBarMessage(get(error, 'message', 'error'));
        });
    } else {
      setShowLoader(false);
      showSnackBarMessage(errorMsg, 'error');
    }
  };

  const checkSubCategoryStatus = subCategories => {
    const isShownCount = subCategories.filter(
      category => !category.IsShown
    ).length;
    return isShownCount !== subCategories.length - 1;
  };

  return {
    centreData,
    tempIconShowValue,
    tempTouchLevel,
    tempRole,
    showModal,
    showModal1,
    showModal2,
    showModal3,
    showModal4,
    setShowModal,
    setShowModal1,
    setShowModal2,
    setShowModal3,
    setShowModal4,
    setTempTouchLevel,
    setTempIconShowValue,
    setTempRole,
    handleSelectChange,
    handleChange,
    handleMappingChange,
    handleRoleChange,
    handleIconClick,
    handleSubmit,
    handleHideSub,
    checkSubCategoryStatus,
  };
};

export default UpdateCentrePIMHandler;
